import { WidgetProps } from "../../Widget/index";
import { action } from "mobx";

const { observable } = require("mobx");

class YoutubeWidgetProps extends WidgetProps {
  /**
   * @var {string}
   */
  @observable videoId;

  /**
   * @retrun {object}
   */
  toJson() {
    return {
      video_id: this.videoId,
    };
  }

  /**
   * @param {object} json
   * @retrun {this}
   */
  @action fromJson(props) {
    this.videoId = props.video_id;

    return this;
  }


    toSnapshot() {
        return {
            videoId: this.videoId
        }
    }

  @action fromSnapshot(json) {
      this.videoId = json.videoId
      this.loading = false;
  }

}

export default YoutubeWidgetProps;
