import { WidgetProps } from "../../Widget/index";
import { action } from "mobx";

const { observable } = require("mobx");

class VideoWidgetProps extends WidgetProps{
    /**
     * @var {string}
     */
    @observable videoFile;

    /**
     * @var {bool}
     */
    @observable muted = true;

    /**
     * @var {string}
     */
    @observable trackKey


    /**
     * @retrun {object}
     */
    toJson() {
        return {
            video_file: this.videoFile,
            track_key: this.trackKey
        };
    }

    /**
     * @param {object} json
     * @retrun {this}
     */
    @action fromJson(props) {
        this.videoFile = props.video_file;
        this.trackKey = props.track_key;

        return this;
    }

    toSnapshot() {
        return {
            videoFile: this.videoFile,
            trackKey: this.trackKey,
        }
    }

    @action fromSnapshot(json) {
        this.videoFile = json.videoFile
        this.trackKey = json.trackKey
        this.loading = false;
    }
}

export default VideoWidgetProps;
