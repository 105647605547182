import Api from '../Api';
import axios from 'axios';

/**
 * @return {{{"status":"OK","data":{"needs_refresh":false}}}
 */
export const getScreenRefresh = async (screenId, isPreview, isSpectate, contentVersion) => {
    if (screenId.indexOf('demo-') === 0) {
        const response = await axios.get(`/fixtures/screen-refresh.json`);
        const needsRefresh = await response.data.needs_refresh;
        return !!needsRefresh;
    }

    const params = [
        `screen_id=${screenId}`,
        `content_version=${contentVersion}`,
        isPreview ? 'preview=1': '',
        isSpectate? 'spectate=1': '',
    ];

    const previewParam = params.join('&');
    const response = await Api.get(`/site/screen-refresh?${previewParam}`);
    return await !!response.data.data.needs_refresh;
};
