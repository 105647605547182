import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Widget } from '../../Widget/index';
import ImageWidgetProps from '../models/ImageWidgetProps';
import styles from './ImageWidget.module.less';
import widgetRegister from '../../widgetRegister';
import './views';
import {TrackService} from "../../../../services";

@observer
@widgetRegister({name: 'image', props: ImageWidgetProps})
class ImageWidget extends Widget {
  static propTypes = {
    ...Widget.PropTypes,
    imageFile: PropTypes.string.isRequired,
    trackKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
  };

  render() {
    return <div className={styles.root}>
        {this.getView({
          imageFile: this.props.imageFile,
          onLoad: this.contentLoaded.bind(this),
          onError: this.contentErrored.bind(this),
          className: styles.root,
        })}
    </div>;
  }

  componentDidMount() {
    this.props.zone.loading = true;
  }

  contentLoaded() {
    this.props.zone.loading = false;
    TrackService.logWidgetAppear(this.props.trackKey)
  }

  contentErrored() {
    this.props.zone.loading = false;
  }
}

export default ImageWidget;
