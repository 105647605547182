import {WidgetProps} from "../../Widget/index";
import {DateTime} from 'luxon';
import {observable, action} from "mobx";
import {RootStore} from "../../../../stores";
import moment from "moment";

class ClockWidgetProps extends WidgetProps {
    /**
     * @var {string}
     */
    @observable timezone;

    /**
     * @var {DateTime}
     */
    @observable time;

    /**
     * @var {string}
     */
    @observable nameDaytext;

    /**
     * @var {string}
     */
    @observable bankHolidayText;

    @action setTime() {
        this.time = DateTime.local().setLocale('hu').setZone(this.timezone);
        setTimeout(() => {
            this.setTime();
        }, 1000);
    }

    /**
     * @retrun {object}
     */
    toJson() {
        return {
            timezone: this.timezone
        };
    }

    /**
     * @param {object} json
     * @retrun {this}
     */
    @action fromJson(props) {
        this.timezone = props.timezone;
        this.nameDaytext = 'Kacsa';//;props.name_day_text;
        this.bankHolidayText = 'mária';//props.bank_holiday_text;
        this.setTime();
        return this;
    }

    @action fromSnapshot(json) {
        this.timezone = json.timezone;
        this.time = DateTime.fromISO(json.time)
        this.loading = false;
    }

    toSnapshot() {
        return {
            timezone: this.timezone,
            time: this.time.toISO()
        }
    }


}

export default ClockWidgetProps;
