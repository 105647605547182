import { observable } from "mobx";
import { WidgetProvider } from "../ui/widgets";

class Widget {
  name = "unknown-widget";

  /**
   * Duration in milliseconds
   *
   * @var {number}
   */
  @observable
  duration = null;

  /**
   * @var {WidgetProps}
   */
  @observable
  props;

  /**
   * @param {string} name
   * @param {number} duration
   * @param {object} props
   * @param {object} component
   */
  constructor(name, duration, props) {
    this.name = name;
    this.duration = duration;
    this.props = props;
  }

  /**
   * @param {{
   *  duration: number, // lifetime of widget in milliseconds.
   *  type: string, // unique slug of widget type.
   *  settings?: object // widget specific settings.
   *  snapshot?: object // widget runtime snapshot props
   * }} data
   * @return {this}
   */
  static createInstanceFromJson(data) {
    const widgetName = data.type;
    const widget = WidgetProvider.getWidget(widgetName);

    if (!widget) {
      throw new Error(
        `Widget is not registered!: ${widgetName}. Check ui/widget/index.js\n`
      );
    }
    const props = new widget.props();
    props.name = widgetName;
    if(typeof data.snapshot === 'object') {
        props.fromSnapshot(data.snapshot);
        console.log("load snapshot to", props)
    } else {
        props.fromJson(data.settings);
    }

    const instance = new Widget(widgetName, data.duration, props);

    return instance;
  }
}

export default Widget;
