import React from "react";
import PropTypes from "prop-types";
import WidgetViewProvider from "../../WidgetViewProvider";
import { motion, AnimatePresence } from 'framer-motion';

class Widget extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    zone: PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      loading: PropTypes.bool.isRequired,
      layout: PropTypes.shape({}).isRequired,
    }).isRequired,
    visible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    visible: true,
  };

  getView(props) {
    const view = WidgetViewProvider.getWidgetViewForZone(
      this.props.name,
      this.props.zone
    );

    let unikey = this.props.name;

    if (this.props.name === "text") {
      unikey = this.props.headline;
    } else if (this.props.name === "video") {
      unikey = this.props.videoFile;
    } else if (this.props.name === "image") {
      unikey = this.props.imageFile;
    } else {
      unikey = this.props.name;
    }

    return (
      <AnimatePresence>
        {console.log('EZZ:', unikey)}
        {this.props.visible && (
          <motion.div
            data-animation="opacity"
            key={unikey}
            exit={{ opacity:0 }}
            initial={{ opacity:0 }}
            animate={{ opacity:1 }}
            transition={{ duration: 1.5 }}
            style={{ height: '100%', width: '100%' }}
          >
            {view !== null && React.createElement(view, props || this.props)}
          </motion.div>
        )}
        </AnimatePresence>
    );
  }
}

export default Widget;
