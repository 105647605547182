import { WidgetProps } from "../../Widget/index";
import { action } from "mobx";

const { observable } = require("mobx");

class ImageWidgetProps extends WidgetProps{
    /**
     * @var {string}
     */
    @observable imageFile;

    /**
     * @var {string}
     */
    @observable trackKey;


    /**
     * @retrun {object}
     */
    toJson() {
        return {
            image_file: this.imageFile,
            track_key: this.trackKey
        };
    }

    /**
     * @param {object} json
     * @retrun {this}
     */
    @action fromJson(props) {
        this.imageFile = props.image_file;
        this.trackKey = props.track_key

        return this;
    }

    toSnapshot() {
        return {
            imageFile:this.imageFile,
            trackKey:this.trackKey,
        }
    }

    @action fromSnapshot(json) {
        this.imageFile = json.imageFile;
        this.trackKey = json.trackKey;
        this.loading = false;
    }
}

export default ImageWidgetProps;
