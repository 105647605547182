import SettingStore from "./SettingStore";
import AppStore from "./AppStore";

class RootStore {

    /**
     * @var {SettingStore}
     * */
    settingStore

    /**
     * @var {AppStore}
     * */
    appStore

    constructor() {
        this.settingStore = new SettingStore();
        this.appStore = new AppStore(this.settingStore.screenId, this.settingStore.isPreview, this);
        console.log(this)
    }
}

export default new RootStore()
