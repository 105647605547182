class SettingStore {

    /**@var {string} */
    screenId

    /**@var {boolean} */
    isPreview = false

    /**@var {boolean} */
    animationOff = false

    /**@var {boolean} */
    spectateOn = false


    currentTime = null;


    snapshot_id

    constructor() {
        const routeParams = window.location.pathname.split('/');

        const screenId = routeParams[1];

        this.screenId = screenId !== undefined ? screenId : ''

        const queryParams = this.getParams();

        this.spectateOn = queryParams.spectate === '1' || routeParams.includes('spectate');

        this.animationOff = queryParams.animation === '0';

        if (queryParams.snapshot_id && queryParams.snapshot_id > 0) {
            this.snapshot_id = queryParams.snapshot_id;
        } else {
            this.snapshot_id = false;
        }

        if (routeParams.map(param => param.toLowerCase()).includes('preview')) {
            this.isPreview = true;
        }
        console.log(this)
    }

    getParams() {
        let search = window.location.search
        if (search[0] === '?') {
            search = search.substr(1)
        }
        const queryParts = search.split('&');
        const queryParams = {};

        for (var i = 0; i < queryParts.length; i++) {
            const parts = queryParts[i].split('=');
            if (parts[0].length) {
                queryParams[parts[0]] = parts[1];
            }
        }
        return queryParams;
    }
}

export default SettingStore
