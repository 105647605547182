import Api from '../Api';
import moment from 'moment'
import {RootStore} from "../stores";


class TrackService {

    queue = [];

    supportStorage = false

    isSynchronizing = false;

    queue_max_elements = 1000;
    queue_max_age_seconds = 36000; //1 hour
    queue_retry_time = 60000; //1 min


    constructor() {
        this.supportStorage = Boolean(window.localStorage);
        setInterval(() => {
            this.synchronize()
        }, this.queue_retry_time)
    }

    addQueue(data) {
        this.queue.push({data, created_at: moment().unix(), age: 0})
        if (this.queue.length > this.queue_max_elements) {
            this.queue.splice(0, this.queue.length - this.queue_max_elements)
        }
    }

    async synchronize() {
        if (this.isSynchronizing) {
            return;
        }
        this.isSynchronizing = true;

        if (this.queue.length) {
            //make sure another async not push new error when sync is progress
            let copy = [...this.queue];

            const now = moment().unix();
            copy.forEach((it) => {
                it.age = now - it.created_at;
            })

            //filter too old report data
            copy = copy.filter(it => it.age < this.queue_max_age_seconds)

            this.queue = [];
            const reportData = copy.map(it => it.data);

            if (!await this.sendLog(reportData)) {
                this.queue = [...copy, this.queue]
            }
        }
        this.isSynchronizing = false;
    }

    logWidgetAppear(trackKey) {
        if (!Boolean(trackKey)) {
            return;
        }

        if (RootStore.settingStore.spectateOn || RootStore.settingStore.snapshot_id) {
            console.log("skip log",RootStore.settingStore.spectateOn || RootStore.settingStore.snapshot_id)
            return;
        }

        const data = {
            track_key: trackKey,
            time: moment().toISOString(),
            playlist: RootStore.appStore?.settings?.layout?.zones?.map((z) => {
                const widget = z.currentlyPlayedWidget;

                if(!widget) {
                    console.log("Widget not found", widget, z)
                    return null
                }
                return {
                    zone_id: z.id,
                    resume_playing_from: 0,
                    widgets: [{
                        type: widget.name,
                        duration: widget.duration,
                        snapshot: widget.props.toSnapshot()
                    }]
                }
            }).filter(item => item !== null)
        }
        this.addQueue(data)
        this.synchronize()
    }

    async sendLog(items) {
        if (!items.length) {
            return true;
        }

        let success;
        try {
            await Api.post(`/site/screen-report?screen_id=${RootStore.settingStore.screenId}`, {items})
            success = true;
        } catch (e) {
            console.log(e)
            success = false;
        }
        return success;
    }
}

export default new TrackService()
