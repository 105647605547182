import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Widget } from '../../Widget/index';
import widgetRegister from '../../widgetRegister';
import VideoWidgetProps from '../models/VideoWidgetProps';
import styles from './VideoWidget.module.less';
import './views';
import {TrackService} from "../../../../services";

@observer
@widgetRegister({name: 'video', props: VideoWidgetProps})
class VideoWidget extends Widget {
  static propTypes = {
    ...Widget.PropTypes,
    videoFile: PropTypes.string.isRequired,
    trackKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  onReady

  constructor() {
    super();
    this.onReady = this.handleReadyInternal.bind(this)
  }

  handleReadyInternal() {
    TrackService.logWidgetAppear(this.props.trackKey)
  }

  render() {
    return <div className={styles.root}>
        {this.getView({
          onReady: this.onReady,
          url: this.props.videoFile,
          className: styles.root,
        })}
    </div>;
  }
}

export default React.memo(VideoWidget);
