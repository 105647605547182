import {WidgetProps} from "../../Widget/index";
import {action} from "mobx";
import {DateTime} from 'luxon';
import {Weather} from "..";
import {getWeather} from "../services/WeatherService";
import SettingStore from "../../../../stores/SettingStore";
import {RootStore} from "../../../../stores";

const {observable} = require("mobx");

class WeatherWidgetProps extends WidgetProps {
    /**
     * @var string
     */
    @observable city;

    /**
     * @var string
     */
    @observable country;

    /**
     * @var bool
     */
    @observable enableClock;

    /**
     * @var string
     */
    @observable timezone;

    /**
     * @var DateTime
     */
    @observable time;

    /**
     * @var {'daytime'|'night'}
     */
    @observable dayPart;

    /**
     * @var Weather
     */
    @observable weather;

    @action
    async fetchWeather() {
        const weatherJson = await getWeather(this.city);

        if (weatherJson) {
            if (!this.weather) {
                this.weather = new Weather();
            }
            this.weather.fromJson(weatherJson, this.timezone);
            this.dayPart = (this.time > this.weather.sunrise && this.time < this.weather.sunset) ? 'daytime' : 'night';
        }

        setTimeout(() => {
            this.fetchWeather();
        }, 1000 * 60);
    }

    @action setTime() {
        this.time = DateTime.local().setLocale('hu').setZone(this.timezone);

        setTimeout(() => {
            this.setTime();
        }, this.enableClock ? 1000 : 1000 * 60);
    }

    /**
     * @retrun {object}
     */
    toJson() {
        return {
            city: this.city,
            country: this.country,
            enable_clock: this.enableClock,
            timezone: this.timezone
        };
    }

    /**
     * @param {object} json
     * @retrun {this}
     */
    @action fromJson(props) {
        this.city = props.city;
        this.country = props.country;
        this.enableClock = parseInt(props.enable_clock) === 1 ? true : false; // ehhh.....
        this.timezone = props.timezone || 'Europe/Budapest';
        this.setTime();

        this.loading = true;
        this.fetchWeather().finally(() => {
            this.loading = false;
        });

        return this;
    }

    toSnapshot() {
        return {
            city: this.city,
            country: this.country,
            enable_clock: this.enableClock,
            timezone: this.timezone,
            time: this.time.toISO()
        };
    }

    @action fromSnapshot(snapshot) {
        this.city = snapshot.city;
        this.country = snapshot.country;
        this.enableClock = snapshot.enableClock;
        this.timezone = snapshot.timezone;
        this.time = DateTime.fromISO(snapshot.time)
        this.loading = false;
    }
}

export default WeatherWidgetProps;
