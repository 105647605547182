import {RootStore} from "../stores";
import Api from '../Api';
import axios from 'axios';
import { compatibleApiVersion } from '../../package.json';

/**
 * @return {{
 *  next_mandatory_refresh: number, // utc timestamp.
 *  content_version: number, // utc timestamp.
 *  playlists: {
 *      zone_id: number,
 *      resume_playing_from: number,
 *      widgets: {
 *          duration: number, // lifetime of widget in milliseconds.
 *          type: string, // unique slug of widget type.
 *          settings: object // widget specific settings.
 *      }[]
 *  }[]}
 * }
 */
export const getPlaylist = async (screenId) => {
    if (screenId.indexOf('demo-') === 0) {
        const response = await axios.get(`/fixtures/${screenId}.json`);
        const playlist = await response.data.playlist;
        playlist.version = compatibleApiVersion;
        return playlist;
    }


    const settings = RootStore.settingStore;

    const params = [
        `screen_id=${screenId}`,
        settings.snapshot_id ? `snapshot_id=${settings.snapshot_id}`: '',
        settings.isPreview ? 'preview=0' : '',
    ].join('&');

    const response = await Api.get(`/site/screen-content?${params}`);
    return await response.data;
};
